import React, { useState, useEffect } from 'react'; // Import useState for managing state
import { useNavigate } from 'react-router-dom';
import { useAuthenticator } from '@aws-amplify/ui-react';
import { downloadData, uploadData } from '@aws-amplify/storage';
import { useUser } from './UserContext';
import './Page.css'; // Import the CSS file

// New InputContainer component
const InputContainer = ({ label, index, onInputChange, isSaved, additionalText, data }) => {
    const [isChecked, setIsChecked] = useState(data.active);
    const [inputValue1, setInputValue1] = useState(data.username);
    const [inputValue2, setInputValue2] = useState(data.password);
  
    const handleCheckboxChange = () => {
      const newChecked = !isChecked;
      setIsChecked(newChecked);
      onInputChange(index, { ...data, active: newChecked, username: inputValue1, password: inputValue2 });
    };
  
    const handleInputChange1 = (value) => {
      setInputValue1(value);
      onInputChange(index, { ...data, active: isChecked, username: value, password: inputValue2 });
    };
  
    const handleInputChange2 = (value) => {
      setInputValue2(value);
      onInputChange(index, { ...data, active: isChecked, username: inputValue1, password: value });
    };
  
    return (
      <div className="input-container">
        <span className="book-label">
          {label}
          <span dangerouslySetInnerHTML={{ __html: additionalText }} />
          :
        </span>
        <div className="input-fields">
          <label className="checkbox">
            <input 
              type="checkbox" 
              checked={isChecked} 
              onChange={handleCheckboxChange} 
              style={{ 
                accentColor: isSaved ? 'black' : 'initial',
              }} 
            />
          </label>
          <input 
            type="text" 
            className="input-field" 
            placeholder="Enter your email/username" 
            value={inputValue1}
            onChange={(e) => handleInputChange1(e.target.value)} 
            style={{ fontWeight: isSaved ? 'bold' : 'normal' }} 
          />
          <input 
            type="text" 
            className="input-field" 
            placeholder="Enter your password" 
            value={inputValue2}
            onChange={(e) => handleInputChange2(e.target.value)} 
            style={{ fontWeight: isSaved ? 'bold' : 'normal' }} 
          />
        </div>
      </div>
    );
  };
  
function AccountManager() {
    const navigate = useNavigate();
    const { signOut } = useAuthenticator();
    const { userAttributes, loading } = useUser();

    // Add new state for universal books
    const [universalBooks, setUniversalBooks] = useState(null);

    // Define the S3 paths
    const S3_USER_INFO_CLIENT_PATH = `${userAttributes.state}/${userAttributes.id}/user_info_client.json`;
    const S3_UNIVERSAL_BOOKS_PATH = 'reference/universal_books.json';

    // State variables
    const [isInputChanged, setIsInputChanged] = useState(false);
    const [isSaved, setIsSaved] = useState(false);
    const [inputValues, setInputValues] = useState(null); // Initialize as null to handle loading state
    
    const handleBack = () => {
      navigate('/');
    };

    const handleSignOut = async () => {
      await signOut();
      navigate('/login');
    };

    // First useEffect for universal books
    useEffect(() => {
        const fetchUniversalBooks = async () => {
            try {
                const downloadTask = downloadData({ path: S3_UNIVERSAL_BOOKS_PATH });
                const { body } = await downloadTask.result;
                if (!body) {
                    throw new Error('Download succeeded but response body is empty.');
                }
                const text = await body.text();
                const data = JSON.parse(text);
                const stateBooks = data[userAttributes.state] || {};
                setUniversalBooks(Object.keys(stateBooks));
                console.log('Fetched universal book keys for state:', Object.keys(stateBooks));
            } catch (error) {
                console.error('Error fetching universal books from S3:', error);
                setUniversalBooks([]);
            }
        };

        fetchUniversalBooks();
    }, [userAttributes.state]);

    // Second useEffect that depends on universalBooks
    useEffect(() => {
        // Only proceed if universalBooks is loaded
        if (!universalBooks) return;

        const fetchData = async () => {
            console.log('FetchData: Initiating data fetch.');
            try {
                const downloadTask = downloadData({ path: S3_USER_INFO_CLIENT_PATH });
                const { body } = await downloadTask.result;
                if (!body) {
                    throw new Error('Download succeeded but response body is empty.');
                }
                const text = await body.text();
                const data = JSON.parse(text);
                console.log('FetchData: Parsing JSON data.');
                console.log('FetchData: Parsed data:', data);

                // Now using universalBooks instead of labels
                const initialValues = universalBooks.reduce((acc, label) => {
                    acc[label] = data.client_settings[label] || { active: false, username: '', password: '' };
                    return acc;
                }, {});
                
                setInputValues(initialValues);
                console.log('Fetched input values:', initialValues);
            } catch (error) {
                console.error('Error fetching data from S3:', error);
                const defaultValues = universalBooks.reduce((acc, label) => {
                    acc[label] = { active: false, username: '', password: '' };
                    return acc;
                }, {});
                setInputValues(defaultValues);
            }
        };

        fetchData();
    }, [universalBooks, S3_USER_INFO_CLIENT_PATH]); // Added universalBooks as dependency

    // Handle input changes
    const handleInputChange = (label, updatedData) => {
      setInputValues(prev => ({
        ...prev,
        [label]: updatedData,
      }));
      setIsInputChanged(true);
    };

    // Handle save button click
    const handleSave = async () => {
      try {
        console.log('Saving...');
        // Wrap inputValues in an object with client_settings key
        const jsonString = JSON.stringify({ client_settings: inputValues }, null, 2);

        console.log('Saving2...');
        console.log(jsonString);
        console.log(typeof jsonString); // Should output 'string'

        // Upload the updated JSON back to S3
        const uploadTask = uploadData({
            path: S3_USER_INFO_CLIENT_PATH,
            data: jsonString,
            options: {
              contentType: 'application/json',
            },
        });

        console.log('Saving3...');

        // Await the result of the upload task
        await uploadTask.result;

        console.log('Saving4...');

        setIsInputChanged(false);
        setIsSaved(true);
        // Reset the saved state after a short delay (optional)
        setTimeout(() => setIsSaved(false), 2000);
        alert('Data saved successfully!');
      } catch (error) {
        console.error('Error saving data to S3:', error);
        alert('Failed to save data. Please try again.');
      }
    };

    if (loading || inputValues === null || universalBooks === null) {
        return <h1>Loading...</h1>;
    }

    return (
      <div className="page-container">
        <h2 className="page-h2">Account Manager</h2>
        <div className="page-buttons">
          <button onClick={handleBack}>Back</button>
          <button 
            className="save-button" 
            onClick={handleSave} 
            disabled={!isInputChanged} 
          >
            Save
          </button>
          <button onClick={handleSignOut}>Sign out</button>
        </div>
        <p className="page-text">
          Mark sportsbook as active to enable it for BetPlus services.<br />
          Be sure to save any changes!
        </p>
        {/* Instantiate multiple InputContainer components */}
        <div style={{ marginBottom: '0px' }}>
          {universalBooks.map((label) => (
            <InputContainer 
              key={label} 
              label={label} 
              index={label} // Use label as index
              onInputChange={handleInputChange}
              isSaved={isSaved} 
              additionalText={
                label === 'PENN' ? ' (ESPN)' : 
                label === 'bet365' ? '<strong>**</strong>' : 
                ''
              }
              data={inputValues[label]}
            />
          ))}
        </div>
        <p className="page-text" style={{ fontSize: '0.8em', marginTop: '30px', marginBottom: '100px' }}>
          <strong>**</strong>login may require your <em>username</em> NOT email
        </p>
      </div>
    );
  }

export default AccountManager;
